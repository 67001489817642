.add-event-page {
	.header {
		text-align: center;
		margin-bottom: 20px;
	}

	.form {
		.form-item {
			margin-top: 10px;
			margin-bottom: 20px;
		}

		.image-preview {
			display: flex;
			justify-content: center;
			margin-bottom: 20px;

			ion-img {
				max-width: 100%;
				max-height: 300px;
				border: 1px solid #ccc;
				border-radius: 10px;
			}
		}

		.submit-button {
			display: flex;
			justify-content: center;

			ion-button {
				width: 100%;
				max-width: 300px;
			}
		}

		.error-message {
			color: red;
			font-size: 14px;
			margin: 5px 0 0 15px;
		}
	}
}
