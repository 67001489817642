.event-item {
	display: flex;
	align-items: flex-start;
	margin-bottom: 10px;

	.event-image {
		width: 100px;
		height: 100px;
		object-fit: cover;
		margin-right: 10px;
	}

	.event-details {
		padding: 10px;

		h2 {
			margin: 0;
			font-size: 1.2em;
		}

		p {
			margin: 0.5em 0;
		}

		.event-category {
			color: orange;
			font-size: 0.9em;
		}
	}
}
